<template>
  <MDBContainer fluid class="login-page">
    <MDBRow>
      <!-- cover -->
      <MDBCol class="d-none d-md-block px-0">
        <div class="left-pane">
          <img src="../../assets/logo-2024-2.png" aria-hidden="true" alt="logo" />
          <div class="footnote">
            <h6 class="mb-0"><b>Celebrating $50 million USD claimed! 🎉</b></h6>
            <p class="mb-0">
              <a href="https://www.creatorshield.com/results">Click here</a> to see our new case studies page
            </p>
          </div>
        </div>
      </MDBCol>
      <!-- login form -->
      <MDBCol>
        <div class="d-flex justify-content-center align-items-center" style="position: relative;">
          <div class="card-container" v-if="isForgotPassword">
            <MDBCard class="shadow-2-strong">
              <MDBCardBody class="p-5 text-center email-sent" v-if="isSent">
                <em class="fa fa-envelope mb-5" />
                <h1>Email has been set!</h1>
                <p>
                  Please check your inbox and click the received link to reset
                  your password.
                </p>
              </MDBCardBody>
              <MDBCardBody class="p-5 text-center" v-else>
                <h1 class="mb-5">Forgot Password</h1>
                <div class="form-outline mb-4" style="
                    text-align: left;
                    padding-left: 20px;
                    padding-right: 20px;
                  ">
                  <MDBInput label="Enter Email Address" type="text" v-model="emailForget"
                    @keypress.enter="forgotPassword" />
                </div>

                <button @click="forgotPassword" class="btn btn-primary btn-lg btn-block submit-btn">
                  Send
                </button>
              </MDBCardBody>
            </MDBCard>
            <p class="text-center mt-5">
              Back to
              <a href="#" class="pe-auto fw-bold" @click="toggleSignIn">
                Sign in
              </a>
            </p>
          </div>
          <div class="card-container" v-if="isSignIn">
            <MDBCard class="shadow-2-strong">
              <MDBCardBody class="p-5 text-center">
                <h1 class="mb-5">Sign in to CreatorShield</h1>
                <div class="form-outline mb-4" style="
                    text-align: left;
                    padding-left: 20px;
                    padding-right: 20px;
                  ">
                  <MDBInput label="Email" type="text" v-model="email" @keypress.enter="login" />
                </div>

                <div class="form-outline mb-2" style="
                    text-align: left;
                    padding-left: 20px;
                    padding-right: 20px;
                  ">
                  <MDBInput label="Password" type="password" v-model="password" @keypress.enter="login" />
                </div>
                <div class="form-outline text-end mb-4 forgot-password" style="
                    text-align: left;
                    padding-left: 20px;
                    padding-right: 20px;
                  ">
                  <span @click="toggleForgotPassword">Forgot Password?</span>
                </div>

                <button @click="login" class="btn btn-primary btn-lg btn-block submit-btn">
                  Login
                </button>
              </MDBCardBody>
            </MDBCard>
            <p class="text-center mt-4">
              Don't have an account?
              <a href="https://creatorshield.com/onboarding" target="_blank">
                Sign up
              </a>
            </p>
          </div>
          <p class="tos">By continuing to use our service, you agree to our <a
              href="https://www.creatorshield.com/tos">Terms of Service</a> which may be periodically updated.</p>
        </div>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
  <!-- toast -->
  <MDBToast v-model="toastError" id="basic-danger-example" autohide position="top-right" appendToBody stacking
    width="350px" color="danger" text="white" icon="fas fa-info-circle fa-lg me-2">
    <template #title>Error</template>
    {{ toastMessage }}
  </MDBToast>
</template>

<script setup>
import {
  MDBToast,
  MDBInput,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
} from "mdb-vue-ui-kit";
import axios from "axios";
import { ref, onMounted } from "vue";
import { useTitle } from "@vueuse/core";
import { useRouter } from "vue-router";

const router = useRouter();

const email = ref("");
const password = ref("");
const toastError = ref(false);
const toastMessage = ref("");

onMounted(() => {
  useTitle("Login | CreatorShield");
});

const isSignIn = ref(true);
const login = () => {
  axios
    .post("api/users/login", {
      email: email.value,
      password: password.value,
    })
    .then(function (response) {
      if (response.status === undefined) {
        if (response.response.status == 404) {
          toastError.value = true;
          toastMessage.value = "Incorrect email or password";
          password.value = "";
        } else {
          toastError.value = true;
          toastMessage.value = "An error occurred during request";
          password.value = "";
        }
      } else {
        router.push("/Dashboard");
      }
    })
    .catch(function (error) {
      if (error.response.status == 404) {
        toastError.value = true;
        toastMessage.value = "Incorrect email or password";
        password.value = "";
      } else {
        toastError.value = true;
        toastMessage.value = "An error occurred during request";
        password.value = "";
      }
    });
};

const isForgotPassword = ref(false);
const toggleForgotPassword = () => {
  isSent.value = false;
  isForgotPassword.value = true;
  isSignIn.value = false;
  useTitle("Forgot | CreatorShield");
};

const toggleSignIn = () => {
  isSignIn.value = true;
  isForgotPassword.value = false;
  useTitle("Login | CreatorShield");
};

const emailForget = ref("");
const isSent = ref(false);
const forgotPassword = () => {
  axios
    .post("api/users/forgotPassword", {
      email: emailForget.value,
    })
    .then(function (response) {
      if (response.status === undefined) {
        if (response.response.status == 404) {
          toastError.value = true;
          toastMessage.value = "Email does not exist";
          emailForget.value = "";
        } else {
          toastError.value = true;
          toastMessage.value = "An error occurred during request";
          emailForget.value = "";
        }
      } else {
        isSent.value = true;
      }
    })
    .catch(function (error) {
      if (error.response.status == 404) {
        toastError.value = true;
        toastMessage.value = "Email does not exist";
        emailForget.value = "";
      } else {
        toastError.value = true;
        toastMessage.value = "An error occurred during request";
        emailForget.value = "";
      }
    });
};
</script>

<style>
@import "../../assets/Sailec-Font/fonts.css";

:root {
  --white: #ffffff;
  --primary: #fe632d;
  --secondary: #e2e6ef;
  --accent: #02061b;
  /* #252527 */
}
</style>
<style lang="scss" scoped>
.login-page {
  background-color: #f9f9f9;
  width: 100vw;
  height: 100vh;

  & .d-flex {
    height: 100vh;
  }
}

.card-container {
  min-width: 35vw;

  .card {
    border-radius: 1rem;
    background-color: white;
  }

  h1 {
    font-family: "Sailec Bold", sans-serif !important;
    color: var(--accent);
  }

  .forgot-password {
    font-family: "Sailec Bold", sans-serif;
    font-size: 12px;
    cursor: pointer;
  }

  a {
    font-family: "Sailec Bold", sans-serif;
    font-weight: bold;
    color: var(--accent);
  }
}

.tos {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 24px;
  padding: 0 10px;

  a {
    color: var(--accent);
    text-decoration: underline;
    font-weight: bold;
  }
}

.left-pane {
  height: 100vh;
  background-image: url("../../assets/you-create-we-protect.png"),
    linear-gradient(90deg, #fe632d, #f63939);
  background-size: cover, cover;
  background-position: center center;
  background-repeat: no-repeat, no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;

  @media screen and (max-width: 1199px) {
    background-position: center;
  }

  img[alt~="logo"] {
    width: 200px;
  }

  .footnote {
    color: white;

    h4 {
      font-family: "Sailec Bold", sans-serif !important;
    }

    a {
      color: white;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

.email-sent h1 {
  font-family: "Sailec Bold", sans-serif !important;
  color: var(--accent);
}

.email-sent p {
  font-family: "Sailec Light", sans-serif !important;
  color: var(--accent);
}

.email-sent .fa {
  font-size: 64px;
  color: var(--primary);
}

.submit-btn {
  background-color: var(--primary);
}
</style>
